
import { Component, Vue } from 'vue-property-decorator'
import Multiselect from 'vue-multiselect'
import { getStaffDoctors } from '@/components/doctors/doctors.service'
import { UserIdentity } from '@/models'

@Component({ components: { Multiselect } })
export default class DoctorSelectionView extends Vue {
  public selectedDoctor: UserIdentity | null = null
  public doctorList: UserIdentity[] = []

  public async created() {
    this.doctorList = await getStaffDoctors()
  }
  public getCustomLabel(e: any) {
    return `${e.documentId} - ${e.fullName}`
  }
  public async selectDoctor() {
    const isValid = await this.$refs.observer.validate()
    if (!isValid) {
      return
    }
    if (this.selectedDoctor) {
      localStorage.setItem(
        'selectedDoctor',
        this.selectedDoctor.rmT_UserId.toString()
      )
      const obj = {
        userId: this.selectedDoctor.rmT_UserId,
        fullName: this.selectedDoctor.fullName,
        userName: this.selectedDoctor.documentId
      }
      localStorage.setItem('currentDoctor', JSON.stringify(obj))
      // localStorage.setItem('', this.selectedDoctor.fullName)

      localStorage.setItem('needsADoctor', 'false')
      this.$router.push('/')
    }
  }
}
